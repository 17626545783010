.stack {
  width: 100%;
}

.form {
  width: clamp(45ch, 50%, 75ch);
}

.heading {
  margin-bottom: 1rem;
}
